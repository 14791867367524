/* tslint:disable */
/* eslint-disable */
/**
 * Dataland Backend API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../../../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../../../../../base';
// @ts-ignore
import type { AggregatedFrameworkDataSummary } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { BasicCompanyInformation } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { CompanyAvailableDistinctValues } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { CompanyId } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { CompanyIdAndName } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { CompanyInformation } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { CompanyInformationPatch } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { DataTypeEnum } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { ErrorResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { IdentifierType } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { StoredCompany } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
/**
 * CompanyDataControllerApi - axios parameter creator
 * @export
 */
export const CompanyDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Checks that an identifier of specified type exists.
         * @summary Checks that an identifier of specified type exists.
         * @param {IdentifierType} identifierType 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsIdentifier: async (identifierType: IdentifierType, identifier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifierType' is not null or undefined
            assertParamExists('existsIdentifier', 'identifierType', identifierType)
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('existsIdentifier', 'identifier', identifier)
            const localVarPath = `/companies/identifiers/{identifierType}/{identifier}`
                .replace(`{${"identifierType"}}`, encodeURIComponent(String(identifierType)))
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For each framework retrieves the amount of available reporting periods
         * @summary Retrieve aggregated data summary for all frameworks
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedFrameworkDataSummary: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAggregatedFrameworkDataSummary', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/aggregated-framework-data-summary`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Distinct values for the parameter countryCode and sector are returned
         * @summary Retrieve available distinct values for company search filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableCompanySearchFilters: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/meta-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The basic information about companies via the provided company name/identifier are retrieved and filtered by countryCode, sector and available framework data. Empty/Unspecified filters are ignored.
         * @summary Retrieve just the basic information about specific companies.
         * @param {string} [searchString] Search string used for substring matching. Must be at least 3 characters after trimming.
         * @param {Set<DataTypeEnum>} [dataTypes] 
         * @param {Set<string>} [countryCodes] 
         * @param {Set<string>} [sectors] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies: async (searchString?: string, dataTypes?: Set<DataTypeEnum>, countryCodes?: Set<string>, sectors?: Set<string>, chunkSize?: number, chunkIndex?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (dataTypes) {
                localVarQueryParameter['dataTypes'] = Array.from(dataTypes);
            }

            if (countryCodes) {
                localVarQueryParameter['countryCodes'] = Array.from(countryCodes);
            }

            if (sectors) {
                localVarQueryParameter['sectors'] = Array.from(sectors);
            }

            if (chunkSize !== undefined) {
                localVarQueryParameter['chunkSize'] = chunkSize;
            }

            if (chunkIndex !== undefined) {
                localVarQueryParameter['chunkIndex'] = chunkIndex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Companies identified via the provided company name/identifier are retrieved
         * @summary Retrieve specific companies by searching their names and identifiers
         * @param {string} searchString Search string used for substring matching. Must be at least 3 characters after trimming.
         * @param {number} [resultLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesBySearchString: async (searchString: string, resultLimit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchString' is not null or undefined
            assertParamExists('getCompaniesBySearchString', 'searchString', searchString)
            const localVarPath = `/companies/names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (resultLimit !== undefined) {
                localVarQueryParameter['resultLimit'] = resultLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Company information behind the given company Id is retrieved.
         * @summary Retrieve company information.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyById', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the company ID for an identifier of specified type.
         * @summary Gets the company ID for an identifier of specified type.
         * @param {IdentifierType} identifierType 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyIdByIdentifier: async (identifierType: IdentifierType, identifier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifierType' is not null or undefined
            assertParamExists('getCompanyIdByIdentifier', 'identifierType', identifierType)
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('getCompanyIdByIdentifier', 'identifier', identifier)
            const localVarPath = `/companies/identifiers/{identifierType}/{identifier}`
                .replace(`{${"identifierType"}}`, encodeURIComponent(String(identifierType)))
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Company information behind the given company ID is retrieved.
         * @summary Retrieve company information.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyInfo: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyInfo', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/info`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The number of companies via the provided company name/identifier are retrieved and filtered by countryCode, sector and available framework data. Empty/Unspecified filters are ignored.
         * @summary Retrieve the number of companies satisfying different filters.
         * @param {string} [searchString] Search string used for substring matching. Must be at least 3 characters after trimming.
         * @param {Set<DataTypeEnum>} [dataTypes] 
         * @param {Set<string>} [countryCodes] 
         * @param {Set<string>} [sectors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNumberOfCompanies: async (searchString?: string, dataTypes?: Set<DataTypeEnum>, countryCodes?: Set<string>, sectors?: Set<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/numberOfCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (dataTypes) {
                localVarQueryParameter['dataTypes'] = Array.from(dataTypes);
            }

            if (countryCodes) {
                localVarQueryParameter['countryCodes'] = Array.from(countryCodes);
            }

            if (sectors) {
                localVarQueryParameter['sectors'] = Array.from(sectors);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A list of all company IDs that are currently set as teaser companies (accessible without authentication).
         * @summary Get the company IDs of the teaser companies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeaserCompanies: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/teaser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks if a company exists for the specified companyId.
         * @summary Checks if a company exists for the specified companyId.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCompanyIdValid: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('isCompanyIdValid', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provided fields of the company associated with the given company Id are updated.
         * @summary Update company information selectively
         * @param {string} companyId 
         * @param {CompanyInformationPatch} companyInformationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompanyById: async (companyId: string, companyInformationPatch: CompanyInformationPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('patchCompanyById', 'companyId', companyId)
            // verify required parameter 'companyInformationPatch' is not null or undefined
            assertParamExists('patchCompanyById', 'companyInformationPatch', companyInformationPatch)
            const localVarPath = `/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyInformationPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A new company is added using the provided information, the generated company ID is returned.
         * @summary Add a new company.
         * @param {CompanyInformation} companyInformation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompany: async (companyInformation: CompanyInformation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyInformation' is not null or undefined
            assertParamExists('postCompany', 'companyInformation', companyInformation)
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replace all company information of the company associated with the given company Id
         * @summary Update company information entirely
         * @param {string} companyId 
         * @param {CompanyInformation} companyInformation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyById: async (companyId: string, companyInformation: CompanyInformation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('putCompanyById', 'companyId', companyId)
            // verify required parameter 'companyInformation' is not null or undefined
            assertParamExists('putCompanyById', 'companyInformation', companyInformation)
            const localVarPath = `/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyDataControllerApi - functional programming interface
 * @export
 */
export const CompanyDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Checks that an identifier of specified type exists.
         * @summary Checks that an identifier of specified type exists.
         * @param {IdentifierType} identifierType 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existsIdentifier(identifierType: IdentifierType, identifier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.existsIdentifier(identifierType, identifier, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.existsIdentifier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * For each framework retrieves the amount of available reporting periods
         * @summary Retrieve aggregated data summary for all frameworks
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregatedFrameworkDataSummary(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: AggregatedFrameworkDataSummary; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregatedFrameworkDataSummary(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.getAggregatedFrameworkDataSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Distinct values for the parameter countryCode and sector are returned
         * @summary Retrieve available distinct values for company search filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableCompanySearchFilters(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyAvailableDistinctValues>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableCompanySearchFilters(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.getAvailableCompanySearchFilters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The basic information about companies via the provided company name/identifier are retrieved and filtered by countryCode, sector and available framework data. Empty/Unspecified filters are ignored.
         * @summary Retrieve just the basic information about specific companies.
         * @param {string} [searchString] Search string used for substring matching. Must be at least 3 characters after trimming.
         * @param {Set<DataTypeEnum>} [dataTypes] 
         * @param {Set<string>} [countryCodes] 
         * @param {Set<string>} [sectors] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanies(searchString?: string, dataTypes?: Set<DataTypeEnum>, countryCodes?: Set<string>, sectors?: Set<string>, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BasicCompanyInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanies(searchString, dataTypes, countryCodes, sectors, chunkSize, chunkIndex, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.getCompanies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Companies identified via the provided company name/identifier are retrieved
         * @summary Retrieve specific companies by searching their names and identifiers
         * @param {string} searchString Search string used for substring matching. Must be at least 3 characters after trimming.
         * @param {number} [resultLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesBySearchString(searchString: string, resultLimit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyIdAndName>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesBySearchString(searchString, resultLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.getCompaniesBySearchString']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Company information behind the given company Id is retrieved.
         * @summary Retrieve company information.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyById(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoredCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyById(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.getCompanyById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the company ID for an identifier of specified type.
         * @summary Gets the company ID for an identifier of specified type.
         * @param {IdentifierType} identifierType 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyIdByIdentifier(identifierType: IdentifierType, identifier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyIdByIdentifier(identifierType, identifier, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.getCompanyIdByIdentifier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Company information behind the given company ID is retrieved.
         * @summary Retrieve company information.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyInfo(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyInfo(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.getCompanyInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The number of companies via the provided company name/identifier are retrieved and filtered by countryCode, sector and available framework data. Empty/Unspecified filters are ignored.
         * @summary Retrieve the number of companies satisfying different filters.
         * @param {string} [searchString] Search string used for substring matching. Must be at least 3 characters after trimming.
         * @param {Set<DataTypeEnum>} [dataTypes] 
         * @param {Set<string>} [countryCodes] 
         * @param {Set<string>} [sectors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNumberOfCompanies(searchString?: string, dataTypes?: Set<DataTypeEnum>, countryCodes?: Set<string>, sectors?: Set<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNumberOfCompanies(searchString, dataTypes, countryCodes, sectors, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.getNumberOfCompanies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A list of all company IDs that are currently set as teaser companies (accessible without authentication).
         * @summary Get the company IDs of the teaser companies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeaserCompanies(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeaserCompanies(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.getTeaserCompanies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Checks if a company exists for the specified companyId.
         * @summary Checks if a company exists for the specified companyId.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isCompanyIdValid(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isCompanyIdValid(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.isCompanyIdValid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provided fields of the company associated with the given company Id are updated.
         * @summary Update company information selectively
         * @param {string} companyId 
         * @param {CompanyInformationPatch} companyInformationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCompanyById(companyId: string, companyInformationPatch: CompanyInformationPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoredCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCompanyById(companyId, companyInformationPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.patchCompanyById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A new company is added using the provided information, the generated company ID is returned.
         * @summary Add a new company.
         * @param {CompanyInformation} companyInformation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompany(companyInformation: CompanyInformation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoredCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompany(companyInformation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.postCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Replace all company information of the company associated with the given company Id
         * @summary Update company information entirely
         * @param {string} companyId 
         * @param {CompanyInformation} companyInformation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompanyById(companyId: string, companyInformation: CompanyInformation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoredCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompanyById(companyId, companyInformation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyDataControllerApi.putCompanyById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyDataControllerApi - factory interface
 * @export
 */
export const CompanyDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyDataControllerApiFp(configuration)
    return {
        /**
         * Checks that an identifier of specified type exists.
         * @summary Checks that an identifier of specified type exists.
         * @param {IdentifierType} identifierType 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsIdentifier(identifierType: IdentifierType, identifier: string, options?: any): AxiosPromise<void> {
            return localVarFp.existsIdentifier(identifierType, identifier, options).then((request) => request(axios, basePath));
        },
        /**
         * For each framework retrieves the amount of available reporting periods
         * @summary Retrieve aggregated data summary for all frameworks
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedFrameworkDataSummary(companyId: string, options?: any): AxiosPromise<{ [key: string]: AggregatedFrameworkDataSummary; }> {
            return localVarFp.getAggregatedFrameworkDataSummary(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Distinct values for the parameter countryCode and sector are returned
         * @summary Retrieve available distinct values for company search filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableCompanySearchFilters(options?: any): AxiosPromise<CompanyAvailableDistinctValues> {
            return localVarFp.getAvailableCompanySearchFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * The basic information about companies via the provided company name/identifier are retrieved and filtered by countryCode, sector and available framework data. Empty/Unspecified filters are ignored.
         * @summary Retrieve just the basic information about specific companies.
         * @param {string} [searchString] Search string used for substring matching. Must be at least 3 characters after trimming.
         * @param {Set<DataTypeEnum>} [dataTypes] 
         * @param {Set<string>} [countryCodes] 
         * @param {Set<string>} [sectors] 
         * @param {number} [chunkSize] 
         * @param {number} [chunkIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies(searchString?: string, dataTypes?: Set<DataTypeEnum>, countryCodes?: Set<string>, sectors?: Set<string>, chunkSize?: number, chunkIndex?: number, options?: any): AxiosPromise<Array<BasicCompanyInformation>> {
            return localVarFp.getCompanies(searchString, dataTypes, countryCodes, sectors, chunkSize, chunkIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * Companies identified via the provided company name/identifier are retrieved
         * @summary Retrieve specific companies by searching their names and identifiers
         * @param {string} searchString Search string used for substring matching. Must be at least 3 characters after trimming.
         * @param {number} [resultLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesBySearchString(searchString: string, resultLimit?: number, options?: any): AxiosPromise<Array<CompanyIdAndName>> {
            return localVarFp.getCompaniesBySearchString(searchString, resultLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * Company information behind the given company Id is retrieved.
         * @summary Retrieve company information.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(companyId: string, options?: any): AxiosPromise<StoredCompany> {
            return localVarFp.getCompanyById(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the company ID for an identifier of specified type.
         * @summary Gets the company ID for an identifier of specified type.
         * @param {IdentifierType} identifierType 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyIdByIdentifier(identifierType: IdentifierType, identifier: string, options?: any): AxiosPromise<CompanyId> {
            return localVarFp.getCompanyIdByIdentifier(identifierType, identifier, options).then((request) => request(axios, basePath));
        },
        /**
         * Company information behind the given company ID is retrieved.
         * @summary Retrieve company information.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyInfo(companyId: string, options?: any): AxiosPromise<CompanyInformation> {
            return localVarFp.getCompanyInfo(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * The number of companies via the provided company name/identifier are retrieved and filtered by countryCode, sector and available framework data. Empty/Unspecified filters are ignored.
         * @summary Retrieve the number of companies satisfying different filters.
         * @param {string} [searchString] Search string used for substring matching. Must be at least 3 characters after trimming.
         * @param {Set<DataTypeEnum>} [dataTypes] 
         * @param {Set<string>} [countryCodes] 
         * @param {Set<string>} [sectors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNumberOfCompanies(searchString?: string, dataTypes?: Set<DataTypeEnum>, countryCodes?: Set<string>, sectors?: Set<string>, options?: any): AxiosPromise<number> {
            return localVarFp.getNumberOfCompanies(searchString, dataTypes, countryCodes, sectors, options).then((request) => request(axios, basePath));
        },
        /**
         * A list of all company IDs that are currently set as teaser companies (accessible without authentication).
         * @summary Get the company IDs of the teaser companies.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeaserCompanies(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getTeaserCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * Checks if a company exists for the specified companyId.
         * @summary Checks if a company exists for the specified companyId.
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCompanyIdValid(companyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.isCompanyIdValid(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Provided fields of the company associated with the given company Id are updated.
         * @summary Update company information selectively
         * @param {string} companyId 
         * @param {CompanyInformationPatch} companyInformationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompanyById(companyId: string, companyInformationPatch: CompanyInformationPatch, options?: any): AxiosPromise<StoredCompany> {
            return localVarFp.patchCompanyById(companyId, companyInformationPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * A new company is added using the provided information, the generated company ID is returned.
         * @summary Add a new company.
         * @param {CompanyInformation} companyInformation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompany(companyInformation: CompanyInformation, options?: any): AxiosPromise<StoredCompany> {
            return localVarFp.postCompany(companyInformation, options).then((request) => request(axios, basePath));
        },
        /**
         * Replace all company information of the company associated with the given company Id
         * @summary Update company information entirely
         * @param {string} companyId 
         * @param {CompanyInformation} companyInformation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyById(companyId: string, companyInformation: CompanyInformation, options?: any): AxiosPromise<StoredCompany> {
            return localVarFp.putCompanyById(companyId, companyInformation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyDataControllerApi - interface
 * @export
 * @interface CompanyDataControllerApi
 */
export interface CompanyDataControllerApiInterface {
    /**
     * Checks that an identifier of specified type exists.
     * @summary Checks that an identifier of specified type exists.
     * @param {IdentifierType} identifierType 
     * @param {string} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    existsIdentifier(identifierType: IdentifierType, identifier: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * For each framework retrieves the amount of available reporting periods
     * @summary Retrieve aggregated data summary for all frameworks
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    getAggregatedFrameworkDataSummary(companyId: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: AggregatedFrameworkDataSummary; }>;

    /**
     * Distinct values for the parameter countryCode and sector are returned
     * @summary Retrieve available distinct values for company search filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    getAvailableCompanySearchFilters(options?: RawAxiosRequestConfig): AxiosPromise<CompanyAvailableDistinctValues>;

    /**
     * The basic information about companies via the provided company name/identifier are retrieved and filtered by countryCode, sector and available framework data. Empty/Unspecified filters are ignored.
     * @summary Retrieve just the basic information about specific companies.
     * @param {string} [searchString] Search string used for substring matching. Must be at least 3 characters after trimming.
     * @param {Set<DataTypeEnum>} [dataTypes] 
     * @param {Set<string>} [countryCodes] 
     * @param {Set<string>} [sectors] 
     * @param {number} [chunkSize] 
     * @param {number} [chunkIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    getCompanies(searchString?: string, dataTypes?: Set<DataTypeEnum>, countryCodes?: Set<string>, sectors?: Set<string>, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BasicCompanyInformation>>;

    /**
     * Companies identified via the provided company name/identifier are retrieved
     * @summary Retrieve specific companies by searching their names and identifiers
     * @param {string} searchString Search string used for substring matching. Must be at least 3 characters after trimming.
     * @param {number} [resultLimit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    getCompaniesBySearchString(searchString: string, resultLimit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<CompanyIdAndName>>;

    /**
     * Company information behind the given company Id is retrieved.
     * @summary Retrieve company information.
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    getCompanyById(companyId: string, options?: RawAxiosRequestConfig): AxiosPromise<StoredCompany>;

    /**
     * Get the company ID for an identifier of specified type.
     * @summary Gets the company ID for an identifier of specified type.
     * @param {IdentifierType} identifierType 
     * @param {string} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    getCompanyIdByIdentifier(identifierType: IdentifierType, identifier: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyId>;

    /**
     * Company information behind the given company ID is retrieved.
     * @summary Retrieve company information.
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    getCompanyInfo(companyId: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyInformation>;

    /**
     * The number of companies via the provided company name/identifier are retrieved and filtered by countryCode, sector and available framework data. Empty/Unspecified filters are ignored.
     * @summary Retrieve the number of companies satisfying different filters.
     * @param {string} [searchString] Search string used for substring matching. Must be at least 3 characters after trimming.
     * @param {Set<DataTypeEnum>} [dataTypes] 
     * @param {Set<string>} [countryCodes] 
     * @param {Set<string>} [sectors] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    getNumberOfCompanies(searchString?: string, dataTypes?: Set<DataTypeEnum>, countryCodes?: Set<string>, sectors?: Set<string>, options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * A list of all company IDs that are currently set as teaser companies (accessible without authentication).
     * @summary Get the company IDs of the teaser companies.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    getTeaserCompanies(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * Checks if a company exists for the specified companyId.
     * @summary Checks if a company exists for the specified companyId.
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    isCompanyIdValid(companyId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Provided fields of the company associated with the given company Id are updated.
     * @summary Update company information selectively
     * @param {string} companyId 
     * @param {CompanyInformationPatch} companyInformationPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    patchCompanyById(companyId: string, companyInformationPatch: CompanyInformationPatch, options?: RawAxiosRequestConfig): AxiosPromise<StoredCompany>;

    /**
     * A new company is added using the provided information, the generated company ID is returned.
     * @summary Add a new company.
     * @param {CompanyInformation} companyInformation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    postCompany(companyInformation: CompanyInformation, options?: RawAxiosRequestConfig): AxiosPromise<StoredCompany>;

    /**
     * Replace all company information of the company associated with the given company Id
     * @summary Update company information entirely
     * @param {string} companyId 
     * @param {CompanyInformation} companyInformation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApiInterface
     */
    putCompanyById(companyId: string, companyInformation: CompanyInformation, options?: RawAxiosRequestConfig): AxiosPromise<StoredCompany>;

}

/**
 * CompanyDataControllerApi - object-oriented interface
 * @export
 * @class CompanyDataControllerApi
 * @extends {BaseAPI}
 */
export class CompanyDataControllerApi extends BaseAPI implements CompanyDataControllerApiInterface {
    /**
     * Checks that an identifier of specified type exists.
     * @summary Checks that an identifier of specified type exists.
     * @param {IdentifierType} identifierType 
     * @param {string} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public existsIdentifier(identifierType: IdentifierType, identifier: string, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).existsIdentifier(identifierType, identifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For each framework retrieves the amount of available reporting periods
     * @summary Retrieve aggregated data summary for all frameworks
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public getAggregatedFrameworkDataSummary(companyId: string, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).getAggregatedFrameworkDataSummary(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Distinct values for the parameter countryCode and sector are returned
     * @summary Retrieve available distinct values for company search filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public getAvailableCompanySearchFilters(options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).getAvailableCompanySearchFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The basic information about companies via the provided company name/identifier are retrieved and filtered by countryCode, sector and available framework data. Empty/Unspecified filters are ignored.
     * @summary Retrieve just the basic information about specific companies.
     * @param {string} [searchString] Search string used for substring matching. Must be at least 3 characters after trimming.
     * @param {Set<DataTypeEnum>} [dataTypes] 
     * @param {Set<string>} [countryCodes] 
     * @param {Set<string>} [sectors] 
     * @param {number} [chunkSize] 
     * @param {number} [chunkIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public getCompanies(searchString?: string, dataTypes?: Set<DataTypeEnum>, countryCodes?: Set<string>, sectors?: Set<string>, chunkSize?: number, chunkIndex?: number, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).getCompanies(searchString, dataTypes, countryCodes, sectors, chunkSize, chunkIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Companies identified via the provided company name/identifier are retrieved
     * @summary Retrieve specific companies by searching their names and identifiers
     * @param {string} searchString Search string used for substring matching. Must be at least 3 characters after trimming.
     * @param {number} [resultLimit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public getCompaniesBySearchString(searchString: string, resultLimit?: number, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).getCompaniesBySearchString(searchString, resultLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Company information behind the given company Id is retrieved.
     * @summary Retrieve company information.
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public getCompanyById(companyId: string, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).getCompanyById(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the company ID for an identifier of specified type.
     * @summary Gets the company ID for an identifier of specified type.
     * @param {IdentifierType} identifierType 
     * @param {string} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public getCompanyIdByIdentifier(identifierType: IdentifierType, identifier: string, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).getCompanyIdByIdentifier(identifierType, identifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Company information behind the given company ID is retrieved.
     * @summary Retrieve company information.
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public getCompanyInfo(companyId: string, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).getCompanyInfo(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The number of companies via the provided company name/identifier are retrieved and filtered by countryCode, sector and available framework data. Empty/Unspecified filters are ignored.
     * @summary Retrieve the number of companies satisfying different filters.
     * @param {string} [searchString] Search string used for substring matching. Must be at least 3 characters after trimming.
     * @param {Set<DataTypeEnum>} [dataTypes] 
     * @param {Set<string>} [countryCodes] 
     * @param {Set<string>} [sectors] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public getNumberOfCompanies(searchString?: string, dataTypes?: Set<DataTypeEnum>, countryCodes?: Set<string>, sectors?: Set<string>, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).getNumberOfCompanies(searchString, dataTypes, countryCodes, sectors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A list of all company IDs that are currently set as teaser companies (accessible without authentication).
     * @summary Get the company IDs of the teaser companies.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public getTeaserCompanies(options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).getTeaserCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks if a company exists for the specified companyId.
     * @summary Checks if a company exists for the specified companyId.
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public isCompanyIdValid(companyId: string, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).isCompanyIdValid(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provided fields of the company associated with the given company Id are updated.
     * @summary Update company information selectively
     * @param {string} companyId 
     * @param {CompanyInformationPatch} companyInformationPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public patchCompanyById(companyId: string, companyInformationPatch: CompanyInformationPatch, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).patchCompanyById(companyId, companyInformationPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A new company is added using the provided information, the generated company ID is returned.
     * @summary Add a new company.
     * @param {CompanyInformation} companyInformation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public postCompany(companyInformation: CompanyInformation, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).postCompany(companyInformation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replace all company information of the company associated with the given company Id
     * @summary Update company information entirely
     * @param {string} companyId 
     * @param {CompanyInformation} companyInformation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataControllerApi
     */
    public putCompanyById(companyId: string, companyInformation: CompanyInformation, options?: RawAxiosRequestConfig) {
        return CompanyDataControllerApiFp(this.configuration).putCompanyById(companyId, companyInformation, options).then((request) => request(this.axios, this.basePath));
    }
}

